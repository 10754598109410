<template>
  <div class="container">
    <div class="nav row-between-center">
      <div class="row-start-center">
        <img class="logo" src="https://img.chaolu.com.cn/MINI/coach-feelings/logo.png" alt="" />
        <div>
          <p class="f28 fw6">超鹿运动App</p>
          <p class="f22 grey">可按次付费的健身房</p>
        </div>
      </div>
      <div class="open-button row-center-center fw6 f24">打开App <wx-open-app :extinfo="extinfo"></wx-open-app></div>
    </div>

    <div class="main row-between-start flex-wrap">
      <div @click="jump(item.motionId)" class="item" v-for="item,index in motionList" :key="index">
        <img :src="item.image" alt="">
        <p class="f28">{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import wxOpenApp from '@/components/wx-open-app'
import { getParam, appOpenWeb, newAppBack } from '@/lib/utils'
import { openApp } from '@/lib/wx-sdk'

export default {
  components: {
    wxOpenApp
  },
  data() {
    return {
      searchName: '',
      motionList: [],
      extinfo: '杰尼龟',
    }
  },
  async mounted() {
    this.searchName = decodeURIComponent(getParam().searchName)
    this.getMotionList()
    openApp()


  },

  methods: {
    newAppBack,
    getMotionList() {
      this.$axios.post(this.baseURLApp + '/c/motion/authorityMotionSearch', {
        searchName: this.searchName
      }).then((res) => {
        this.motionList = res.data
      })
    },
    jump(id) {
      appOpenWeb('', `${window.location.origin}/#/training-record/motion?motionId=${id}`)
    },

  },
}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}
.container {
  background: #F5F5F5;
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }

  .nav {
    width: 750px;
    height: 128px;
    background: #ffffff;
    border-bottom: 1px solid #eeeeee;
    padding: 0 36px;
    position: sticky;
    z-index: 9;
    top: 0;
    .logo {
      width: 76px;
      margin: 0 20px 0 0;
    }
    .open-button {
      width: 146px;
      height: 56px;
      background: #FFDE00;
      border-radius: 4px 4px 4px 4px;
    }
  }
  .main {
    padding: 32px;
    .item {
      width: 330px;
      height: 426px;
      background: #FFFFFF;
      border-radius: 12px 12px 12px 12px;
      margin: 0 0 28px;
      position: relative;
      padding: 16px;
      text-align: center;
      img {
        width: 100%;
        height: 298px;
        object-fit: contain;
      }
      p {
        margin: 12px 0 0;
      }
    }
  }
}
.grey {
  color: #6c727a;
}
</style>
